//src/ErrorBoundary.js

import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.error = null; // Instance variable for error
    this.errorInfo = null; // Instance variable for errorInfo
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render shows the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Store error details in instance variables
    this.error = error;
    this.errorInfo = errorInfo;

    // Log the error to an error reporting service
    console.error('ErrorBoundary caught an error:', error, errorInfo);
    // Example: Sentry.captureException(error, { extra: errorInfo });
  }

  handleRetry = () => {
    // Reset the error state to allow retrying the operation
    this.setState({ hasError: false });
    this.error = null;
    this.errorInfo = null;
  };

  render() {
    if (this.state.hasError) {
      // Render fallback UI
      return (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <h1>Oops! Something went wrong.</h1>
          <p>
            An unexpected error has occurred. Please try refreshing the page or
            clicking the button below to retry.
          </p>
          {process.env.NODE_ENV === 'development' && this.errorInfo && (
            <details style={{ whiteSpace: 'pre-wrap', textAlign: 'left' }}>
              <summary>Error Details</summary>
              <p><strong>{this.error && this.error.toString()}</strong></p>
              <pre>{this.errorInfo.componentStack}</pre>
            </details>
          )}
          <button onClick={this.handleRetry} style={{ marginTop: '20px' }}>
            Retry
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
