// src/index.js

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom'; // Use ReactDOM for React 17
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import App from './App';
import './index.css';
import LoadingScreen from './LoadingScreen';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// Create your MUI theme
const theme = createTheme({
  // Add your theme configuration here
});

// Initialize app with ReactDOM.render for React 17
ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ThemeProvider theme={theme}>
        <Suspense fallback={<LoadingScreen />}>
          <App />
        </Suspense>
      </ThemeProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// Register service worker only in production
if (process.env.NODE_ENV === 'production') {
  serviceWorkerRegistration.register({
    onSuccess: (registration) => {
      console.log('🚀 Service Worker registered successfully:', {
        scope: registration.scope,
        timestamp: new Date().toISOString(),
      });
    },
    onUpdate: (registration) => {
      console.log('🔄 Service Worker update available:', {
        state: registration.installing?.state,
        timestamp: new Date().toISOString(),
      });
      // Optionally, prompt the user to refresh the page
    },
  });
} else {
  console.log('🛠️ Service Worker registration skipped (development mode)');
  // Unregister any existing service workers in development
  serviceWorkerRegistration.unregister();
}
