// src/ProtectedRoute.js
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useUser } from './contexts/userContext';
import LoadingScreen from './LoadingScreen';

const ProtectedRoute = ({ children }) => {
  const { user, isLoading } = useUser();
  const location = useLocation();

  console.log('[ProtectedRoute] component: Checking access for protected route');
  console.log('[ProtectedRoute] component: isLoading:', isLoading, 'user:', user);

  if (isLoading) {
    console.log('[ProtectedRoute] component: Still loading user state, displaying LoadingScreen');
    return <LoadingScreen />;
  }

  if (!user) {
    console.warn('[ProtectedRoute] component: No user found, redirecting to login');
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  console.log('[ProtectedRoute] component: User authenticated, rendering children');
  return children;
};

export default ProtectedRoute;
