// GoalStateManager.js

// This file is a state manager for goals in a React application.
// It uses React Context to provide a global state that can be shared across components.
// Components can access and manipulate goal-related data, such as adding, updating, and removing goals.

import React, { createContext, useState, useContext, useCallback } from 'react';
import axios from 'axios'; // Assuming you are using axios for HTTP requests

// Creating a context for goal data
const GoalContext = createContext();

// Custom hook to allow easy access to the goal context data and functions
export const useGoals = () => useContext(GoalContext);

// Provider component that wraps child components to provide them access to goal context
export const GoalProvider = ({ children }) => {
  // State to store the list of goals
  const [savedGoals, setSavedGoals] = useState([]);

  // Function to add a new goal to the list of saved goals
  const addGoal = useCallback((newGoal) => {
    setSavedGoals((prevGoals) => [...prevGoals, newGoal]);
  }, []);

  // Function to remove a goal from the list of saved goals
  const removeGoal = useCallback((goalId) => {
    setSavedGoals((prevGoals) => prevGoals.filter((goal) => goal.id !== goalId));
  }, []);

  // Function to update an existing goal in the list of saved goals
  const updateGoal = useCallback((updatedGoal) => {
    setSavedGoals((prevGoals) =>
      prevGoals.map((goal) => (goal.id === updatedGoal.id ? updatedGoal : goal))
    );
  }, []);

  // Function to convert advice into a goal and add it to the list of saved goals
  const addAdviceAsGoal = async (advice) => {
    try {
      const { data: newGoal } = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/goals`, {
        userId: advice.userId, // Ensure this is the actual userId from your authentication context or stored token
        username: advice.username,
        goal: advice.userProblem,
        completion: 0,
        type: 'long_term', // or 'short_term', depending on your logic
        details: advice.shortTermGoals.concat(advice.longTermGoals),
      }, {
        headers: {
          'Authorization': `Bearer ${yourAuthTokenHere}`, // Make sure to replace `yourAuthTokenHere` with your actual auth token
        },
      });

      // If the goal creation is successful, add the new goal to the state
      addGoal(newGoal);
    } catch (error) {
      console.error('Error converting advice into goal:', error);
      // Handle the error. For example, you might want to display an error message to the user
    }
  };

  // Providing the goal context value to child components, which includes
  // the saved goals array and functions for adding, removing, updating goals, and adding advice as a goal
  return (
    <GoalContext.Provider value={{ savedGoals, setSavedGoals, addGoal, removeGoal, updateGoal, addAdviceAsGoal }}>
      {children}
    </GoalContext.Provider>
  );
};

export default GoalProvider;
