import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';

const LoadingScreen = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Player
        autoplay
        loop
        src="/lotties/Loading_lottie.json" // Make sure this is the correct path to your Lottie file
        style={{ height: '200px', width: '200px' }}
      />
      <p style={{ marginTop: '20px', fontSize: '18px', color: '#22489E' }}>Loading...</p>
    </div>
  );
};

export default LoadingScreen;
