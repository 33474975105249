import React, { createContext, useState, useContext } from 'react';

const AchievementsContext = createContext();

export const useAchievements = () => useContext(AchievementsContext);

export const AchievementsProvider = ({ children }) => {
  const [achievements, setAchievements] = useState([]);

  const addAchievement = (achievement) => {
    setAchievements(prev => [...prev, achievement]);
  };

  return (
    <AchievementsContext.Provider value={{ achievements, addAchievement }}>
      {children}
    </AchievementsContext.Provider>
  );
};
