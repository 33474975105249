// src/contexts/userContext.js

import React, { createContext, useState, useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import authApiClient from '../authApiClient';

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const isMountedRef = useRef(true);
  const location = useLocation(); // Use useLocation to get the current path

  const login = (userData) => {
    setUser(userData);
  };

  const logout = () => {
    setUser(null);
    // Additional logout logic if necessary
  };

  const checkAuth = async () => {
    console.log('[userContext] checkAuth: Start - Checking user authentication state');
    console.log('[userContext] checkAuth: Current path is:', location.pathname);
  
    // List of public paths where authentication check should be skipped
    const publicPaths = ['/', '/about', '/contact', '/login', '/signup', '/pricing'];
    const currentPath = location.pathname;
  
    // Skip auth check if the current path is public
    if (publicPaths.includes(currentPath)) {
      console.log(`[userContext] checkAuth: Skipping authentication check for public path: ${currentPath}`);
      if (isMountedRef.current) {
        console.log('[userContext] checkAuth: Setting isLoading to false (public path)');
        setIsLoading(false);
      }
      return;
    }
  
    console.log(`[userContext] checkAuth: Authentication required for path: ${currentPath}`);
  
    // Set isLoading to true at the start
    if (isMountedRef.current) {
      console.log('[userContext] checkAuth: Setting isLoading to true');
      setIsLoading(true);
    }
  
    try {
      console.log('[userContext] checkAuth: Sending request to /users/me');
      const response = await authApiClient.get('/users/me');
      console.log('[userContext] checkAuth: Response received:', response.data);
  
      if (isMountedRef.current) {
        console.log('[userContext] checkAuth: User authenticated, setting user data');
        setUser(response.data);
      }
    } catch (error) {
      console.error('[userContext] checkAuth: Error verifying user:', error);
      if (isMountedRef.current) {
        console.log('[userContext] checkAuth: Clearing user data due to error');
        setUser(null);
      }
    } finally {
      if (isMountedRef.current) {
        console.log('[userContext] checkAuth: Setting isLoading to false');
        setIsLoading(false);
      }
    }
  };
  

  useEffect(() => {
    console.log('[userContext] useEffect: Component mounted or location changed, checking authentication');

    checkAuth();

    return () => {
      console.log('[userContext] useEffect cleanup: Component unmounting');
      isMountedRef.current = false;
    };
  }, [location.pathname]); // Depend on location.pathname

  console.log('[userContext] Current state - user:', user, 'isLoading:', isLoading);

  return (
    <UserContext.Provider value={{ user, setUser, login, logout, checkAuth, isLoading }}>
      {children}
    </UserContext.Provider>
  );
};

